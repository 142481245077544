/** @define CustomerService; weak */

.CustomerService {
    display: flex;
    min-height: 75vh;
    padding: calc(var(--gutter) * 2) 0;
}

.CustomerService-nav {
    border-right: 1px solid var(--color-medium-gray);
    font-family: var(--font-family-tt-commons-regular);
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: 0 calc(var(--gutter) * 2) 0 var(--gutter);
    text-transform: uppercase;
    width: 25%;

    @media (max-width: ($desktop-width - 1px)) {
        display: none;
    }
}
.CustomerService-navGroup {
    line-height: 1.75;
    list-style: none;
    margin-bottom: calc(var(--gutter) * 2);
    padding: 0;
}
.CustomerService-navItem a.is-active,
.CustomerService-navItem a:hover {
    background-image: linear-gradient(to top, #959595, #959595);
    background-position: left 100%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
}
.CustomerService-navHeading {
    font-family: var(--font-family-tt-commons-demibold);
}

.CustomerService-body {
    padding: 0 var(--gutter);
    width: 100%;

    @media (min-width: $desktop-width) {
        padding: 0 calc(var(--gutter) * 2);
        width: 50%;
    }
}
.CustomerService-block {
    margin-bottom: var(--gutter);

    iframe,
    .mapouter,
    .gmap_canvas {
        width: 100%;
    }
}
.CustomerService-block--form {
    margin: calc(var(--gutter) * 2) 0;
}
