/** @define Checkout; weak */

.adyen-checkout__dropin {
    font-family: var(--font-family-tt-commons-regular);
    letter-spacing: var(--letter-spacing-tt-commons);
    text-transform: uppercase;

    img {
        width: auto;
    }
}

.adyen-checkout__helper-text,
.adyen-checkout__label__text,
.adyen-checkout__error-text {
    font: inherit !important;
}

.adyen-checkout__qr-loader {
    border-radius: 0;
    max-width: 400px;
}

.adyen-checkout__button__text {
    font-size: var(--font-size-tt-commons-m);
}

.Checkout {
    background-color: var(--color-white);
    border-top: 1px solid var(--color-medium-gray);
    display: flex;
    flex-direction: column;

    @media (min-width: $tablet-width) {
        border-right: 1px solid var(--color-medium-gray);
        box-shadow: -1px 0 0 var(--color-medium-gray);
        width: 50%;
    }
}

.Checkout--checkoutPage,
.Checkout--receipt {
    width: 100%;

    @media (min-width: $tablet-width) {
        border-right: 1px solid var(--color-medium-gray);
        width: 66.666%;
    }

    @media (min-width: $desktop-width) {
        width: 50%;
    }
}

.Checkout--checkoutPage {
    border-bottom: 1px solid var(--color-medium-gray);
    margin-bottom: var(--gutter);

    &:first-child {
        margin-top: var(--gutter);
    }

    &:last-child {
        padding-bottom: 5em;
    }

    &.Cart-notice {
        background-color: var(--color-black);
        color: var(--color-white);
        font-family: var(--font-family-tt-commons-regular);
        font-size: var(--font-size-tt-commons-m);
        letter-spacing: 0.04em;
        line-height: 1.4;
        margin-bottom: 0;
        padding: var(--gutter);
    }
}

.Checkout--receipt {
    background-color: var(--color-white);
    border-top: 0;
    margin: 0 auto;
    min-height: 80vh;
    padding-bottom: 6em;
}

.Checkout-header {
    align-items: center;
    border-bottom: 1px solid var(--color-medium-gray);
    display: flex;
    height: calc(var(--row-height) * 1.75);
    padding: 0 var(--gutter);
}

.Checkout-title {
    font-size: var(--font-size-canela-m);
    margin: 0;
}

.Checkout-title--small {
    font-size: var(--font-size-canela-s);
}

.Checkout-groups {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: calc(var(--gutter) / 2);
}

.Checkout-group {
    padding: calc(var(--gutter) / 2);

    &.is-hidden {
        display: none;
    }

    &.is-disabled {
        opacity: 0.5;
    }
}

.Checkout-group--details {
    + .Checkout-group--details {
        border-top: 1px solid var(--color-medium-gray);
        margin-top: var(--gutter);
        padding-top: var(--gutter);
    }
}

.Checkout-group--shippingMethods {
    border-top: 1px solid var(--color-medium-gray);
    margin-top: calc(var(--gutter) / 2);
    padding-top: var(--gutter);
}

.Checkout-group--submit {
    margin-bottom: var(--gutter);
    margin-top: auto;
    padding-top: calc(var(--gutter) * 2);
}

.Checkout-group--borders {
    border-bottom: 1px solid var(--color-medium-gray);
    border-top: 1px solid var(--color-medium-gray);
    padding-bottom: var(--gutter);
    padding-top: var(--gutter);

    & + & {
        border-top: 0;
    }
}

.Checkout-group--marginBottom {
    margin-bottom: var(--gutter);
}

.Checkout-groupSummary {
    cursor: pointer;

    .Checkout-group[open] & {
        margin-bottom: var(--gutter);
    }
}

.Checkout-groupTitle {
    font: inherit;
    font-size: var(--font-size-canela-m);
    margin-bottom: var(--gutter);
}

.Checkout-groupTitle--summary {
    font-family: var(--font-family-canela-deck);
    font-size: var(--font-size-canela-s);
    margin-bottom: 0;

    @media (max-width: ($tablet-width - 1px)) {
        font-size: inherit;
    }

    span {
        content: "Click to show details";
        display: block;
        font-family: var(--font-family-tt-commons-regular);
        font-size: 1rem;
        letter-spacing: var(--letter-spacing-tt-commons);
        text-transform: uppercase;
    }
}

.Checkout-formAction {
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: var(--letter-spacing-tt-commons);
    text-transform: uppercase;

    & + & {
        margin-top: var(--gutter);
    }
}

.Checkout-klarna {
}

.Checkout-toggleLabel {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    letter-spacing: var(--letter-spacing-tt-commons);
    min-height: var(--row-height);
    position: relative;
}

.Checkout-toggleLabel--full {
    grid-column: span 2;
}

.Checkout-toggleLabel--newsletter {
    margin-bottom: calc(var(--gutter) / 2);
    width: 100%;
}

.Checkout-toggleLabel--marginBottom {
    margin-bottom: var(--gutter);
}

.Checkout-toggleCheckbox {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
}

.Checkout-toggleFauxCheckbox {
    align-items: center;
    border: 1px solid var(--color-medium-gray);
    cursor: pointer;
    display: flex;
    height: 1.75em;
    justify-content: center;
    margin-right: calc(var(--gutter) / 2);
    width: 1.75em;

    > .Icon {
        display: none;
    }

    .Checkout-toggleCheckbox:checked ~ & > .Icon {
        display: block;
    }
}

.Checkout-toggleText {
    cursor: pointer;
    font-family: var(--font-family-tt-commons-regular);
    text-transform: uppercase;

    i:last-child:not(:only-child) {
        margin-left: calc(var(--gutter) / 2);
    }

    a {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }
}

.Checkout-toggleDesc {
    display: none;
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: 0.04em;
    line-height: 1.4;
    margin-bottom: var(--gutter);
    margin-top: calc(var(--gutter) / -2);
    width: 100%;

    &:last-child {
        margin-bottom: 0;
        margin-top: calc(var(--gutter) / 2);
    }

    &.is-visible {
        display: block;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.Checkout-voucherForm {
    position: relative;
}

.Checkout-applyVoucher {
    bottom: 0;
    color: var(--color-dark-gray);
    font-family: var(--font-family-tt-commons-regular);
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: calc(var(--gutter) * 0.875) var(--gutter);
    position: absolute;
    right: 0;
    text-transform: uppercase;
}

.Checkout-labels {
    clear: both;
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
}

.Checkout-label {
    border: 1px solid var(--color-medium-gray);
    display: block;
    letter-spacing: var(--letter-spacing-tt-commons);
    min-height: calc(var(--row-height) * 1.75);
    padding: calc(var(--gutter) * 0.875) var(--gutter);
    position: relative;

    &.is-active {
        border-color: var(--color-dark-gray);
    }

    &.is-disabled {
        opacity: 0.75;
    }
}

.Checkout-label--half {
}

.Checkout-label--full {
    grid-column: span 2;
}

.Checkout-labelText {
    display: block;
    font-family: var(--font-family-tt-commons-regular);
    margin-bottom: calc(var(--gutter) * 0.25);
    padding: 0;
    text-transform: uppercase;
}

.Checkout-fauxSelect {
    display: flex;
    justify-content: space-between;
    letter-spacing: initial;
}

.Checkout-input,
.Checkout-textarea,
.Checkout-select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    letter-spacing: initial;
    outline: 0;
    padding: 0;
    width: 100%;
}

.Checkout-input {
}

.Checkout-textarea {
    min-height: 6em;
    resize: vertical;
}

.Checkout-select {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.Checkout-button {
    align-items: center;
    background-color: var(--color-black);
    color: var(--color-white);
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    justify-content: center;
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-top: var(--gutter);
    min-height: calc(var(--row-height) * 1.5);
    padding: 0 var(--gutter);
    text-transform: uppercase;
    width: 100%;

    @media (min-width: $mobile-width) {
        font-size: var(--font-size-tt-commons-l);
    }

    &:disabled {
        opacity: 0.75;
    }

    &:first-child {
        margin-top: 0;
    }
}

.Checkout-button--dull {
    background-color: var(--color-medium-gray);
    color: var(--color-black);
    font-size: var(--font-size-tt-commons-m);
}

.Checkout-submitResponse {
    border-bottom: 1px solid var(--color-medium-gray);
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-top: var(--gutter);
    padding-bottom: var(--gutter);
    text-transform: uppercase;

    &.is-error {
        color: #ca0000;
    }

    a {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }
}

.Checkout-radioButtons {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    gap: var(--gutter);
    justify-content: center;
    padding: calc(var(--gutter) / 2) 0;
}

.Checkout-radioButton {
    align-items: center;
    border: 1px solid var(--color-medium-gray);
    cursor: pointer;
    display: flex;
    flex: 1 1;
    justify-content: center;
    min-height: calc(var(--row-height) * 1.75);
    position: relative;

    &.is-selected {
        border-color: var(--color-dark-gray);
    }

    &.is-disabled {
        cursor: default;
    }
}

.Checkout-radioButton--fullWidth {
    flex: none;
    width: 100%;
}

.Checkout-radioButtonInput {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
}

.Checkout-radioButtonName {
    @include visually-hidden;
}

.Checkout-radioButtonLabel {
    font-family: var(--font-family-tt-commons-regular);
    letter-spacing: var(--letter-spacing-tt-commons);
    text-align: center;
    text-transform: uppercase;
}

.Checkout-radioButtonImage {
    height: 1.75em;
    width: auto;

    &.Checkout-radioButtonImage--swish {
        height: 5em;
    }
}

.Checkout-receiptBody {
    padding: var(--gutter);
}

.Checkout-receiptBodyExtra {
    border-top: 1px solid var(--color-medium-gray);
    padding-top: var(--gutter);
}

.Checkout-receiptItems {
    border-bottom: 1px solid var(--color-medium-gray);
    border-top: 1px solid var(--color-medium-gray);
    margin: var(--gutter) 0;
    padding-top: var(--gutter);
}

.Checkout-definitionList {
    display: grid;
    gap: var(--gutter);
    grid-gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
}

.Checkout-definitionListGroup {
    dt {
        font-family: var(--font-family-tt-commons-regular);
        letter-spacing: var(--letter-spacing-tt-commons);
        text-transform: uppercase;
    }

    dd {
        font-family: var(--font-family-canela-deck);

        @media (min-width: $tablet-width) {
            font-size: var(--font-size-canela-s);
        }
    }

    a {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }
}

.Checkout-orderItems {
    list-style: none;
    margin-top: var(--gutter);
    padding: 0;
}

.Checkout-error {
    background: var(--color-light-gray);
    color: var(--color-dark-gray);
    line-height: 1.5;
    padding: 1em;
}

.Checkout-ingrid {
    grid-column: span 2;
}
