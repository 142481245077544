.CookieDeclaration {
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    line-height: var(--line-height-regular);
    margin: auto;
    margin-top: 4rem;
    max-width: 72rem;
    padding: var(--gutter);

    a {
        text-decoration: underline;
    }
}

.CookieDeclaration .CookieDeclarationType {
    border-color: var(--color-medium-gray);
    margin: calc(var(--gutter) * 2) 0;
    overflow: auto;
    padding: var(--gutter);
}

.CookieDeclarationDialogText {
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    line-height: var(--line-height-regular);
}

.CookieDeclaration .CookieDeclarationTypeHeader {
    font-family: var(--font-family-canela-deck);
    font-size: var(--font-size-canela-l);
    font-weight: normal;
}

.CookieDeclaration .CookieDeclarationType p {
}

.CookieDeclaration .CookieDeclarationType td,
.CookieDeclaration .CookieDeclarationType th {
    padding: 0.5rem 0.25rem;
}

.CookieDeclaration .CookieDeclarationType p {
    margin-bottom: var(--gutter);
}

.CookieDeclaration .CookieDeclarationTable {
    overflow-x: auto;
    table-layout: auto;
}

.CookieDeclaration .CookieDeclarationTable th,
.CookieDeclaration .CookieDeclarationTable td {
    min-width: 8rem;
}

.CookieDeclaration .CookieDeclarationTable tbody tr:last-of-type td {
    border-bottom: 0;
}
