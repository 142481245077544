.Icon {
    font-family: "artilleriet-icons" !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: never;
    text-transform: none;
}

.Icon--chevron-left:before {
    content: "\e916";
}
.Icon--chevron-right:before {
    content: "\e917";
}
.Icon--globe:before {
    content: "\e915";
}
.Icon--info:before {
    content: "\e912";
}
.Icon--minus:before {
    content: "\e90f";
}
.Icon--plus:before {
    content: "\e910";
}
.Icon--arrowRight:before {
    content: "\e90b";
}
.Icon--arrowLeft:before {
    content: "\e90c";
}
.Icon--arrowDown:before {
    content: "\e90d";
}
.Icon--arrowUp:before {
    content: "\e90e";
}
.Icon--gift:before {
    content: "\e908";
}
.Icon--checkmark:before {
    content: "\e909";
}
.Icon--clear:before {
    content: "\e904";
}
.Icon--zoomOut:before {
    content: "\e907";
}
.Icon--menu:before {
    content: "\e903";
}
.Icon--heart:before {
    content: "\e900";
}
.Icon--search:before {
    content: "\e901";
}
.Icon--cart:before {
    content: "\e902";
}
.Icon--filter:before {
    content: "\e905";
}
.Icon--shoppingCart:before {
    content: "\e90a";
}
.Icon--menuSearch:before {
    content: "\e906";
}
.Icon--user:before {
    content: "\e911";
}
.Icon--userCheck:before {
    content: "\e913";
}
.Icon--arrowDouble:before {
    content: "\e914";
}
