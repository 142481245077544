.MobileMenu-modal {
    inset: 0;
    outline: none;
    pointer-events: none;
    position: absolute;
    width: 100%;
}

.MobileMenu {
    align-items: flex-start;
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    min-height: calc(100vh - var(--header-height));
}

.MobileMenu-column {
    align-self: stretch;
    background-color: var(--color-light-gray);
    display: flex;
    flex-direction: column;
    padding: calc(var(--line-height-regular) * 1em + var(--gutter) * 3) 0
        var(--gutter);
    pointer-events: all;
    position: relative;
    width: 100%;
}
.MobileMenu-column--top {
    padding-bottom: 0;
    padding-top: calc(var(--gutter) * 2);
}

.MobileMenu-search {
    display: block;
    padding: 0 var(--gutter);
    position: relative;
}
.MobileMenu-search--results {
    margin: var(--gutter) 0;
}
.MobileMenu-searchInput {
    border: 1px solid var(--color-medium-gray);
    display: block;
    font-family: var(--font-family-canela-deck);
    font-size: var(--font-size-canela-s);
    height: calc(var(--font-size-canela-s) * 2.5);
    letter-spacing: revert;
    line-height: 1;
    outline: 0;
    padding: 0 0.5em;
    width: 100%;
}
.MobileMenu-searchButton,
.MobileMenu-searchSpinner {
    --size: calc(var(--font-size-canela-s) * 2.5);

    height: var(--size);
    position: absolute;
    right: var(--gutter);
    top: 0;
    width: var(--size);
}
.MobileMenu-searchButton {
    i {
        color: var(--color-dark-gray);
        font-size: 1.5rem;
        vertical-align: -0.1em;
    }

    &:hover i {
        color: inherit;
    }

    span {
        @include visually-hidden;
    }
}
.MobileMenu-searchSpinner {
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.MobileMenu-searchResultsHeading {
    font-family: var(--font-family-tt-commons-demibold);
    padding: var(--gutter);
    text-transform: uppercase;
}
.MobileMenu-categorySearchResults {
    display: block;
    list-style: none;
    padding: 0 var(--gutter) var(--gutter);
}
.MobileMenu-categorySearchResult {
    text-transform: uppercase;

    @media (max-width: ($tablet-width - 1px)) {
        padding: 1em 0;
    }

    a:hover {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }

    i {
        margin: 0 0.35em;
    }
    span,
    i {
        color: var(--color-dark-gray);
    }
}

.MobileMenu-searchResults {
    --gutter: 0.95rem;

    border-top: 1px solid var(--color-medium-gray);
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
}
.MobileMenu-searchFooter {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--gutter) * 2);
    padding: 0 var(--gutter) var(--gutter);
}
.MobileMenu-searchPaginationButton {
    background-color: var(--color-black);
    border: 0;
    color: var(--color-white);
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    height: var(--row-height);
    letter-spacing: var(--letter-spacing-tt-commons);
    min-width: 8em;
    padding: 0 var(--gutter);
    text-transform: uppercase;

    &:disabled {
        opacity: 0.65;
    }

    @media (max-width: ($tablet-width - 1px)) {
        min-width: 10em;
    }
}

.MobileMenu-backButton,
.MobileMenu-viewAllLink {
    top: calc(var(--gutter) * 2);

    @media (hover) {
        &:hover span {
            background-image: linear-gradient(to top, #959595, #959595);
            background-position: left 100%;
            background-repeat: no-repeat;
            background-size: 100% 1px;
        }
    }
}
.MobileMenu-backButton {
    border-bottom: 1px solid var(--color-medium-gray);
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    left: 0;
    padding: calc(var(--gutter) * 1.3) calc(var(--gutter) * 3.8);
    position: absolute;
    text-align: left;
    text-transform: uppercase;
    top: 0;
    width: 100%;

    i {
        font-size: calc(var(--gutter) * 1.8);
        left: calc(var(--gutter) * 0.9);
        position: absolute;
        top: calc(var(--gutter));
    }

    /*@media (min-width: $tablet-width) {
        color: var(--color-dark-gray);
        left: calc(var(--gutter) - 0.3em);
    }

    */
}
.MobileMenu-viewAllLink {
    position: absolute;
    right: var(--gutter);
    text-transform: uppercase;
}
.MobileMenu-columnFooter {
    background-color: hsl(0, 0%, 12%);
    color: var(--color-white);
    margin-top: auto;
    padding: var(--gutter) 0 calc(var(--gutter) * 2) 0;

    .MobileMenu-catList {
        background-color: hsl(0, 0%, 12%);
        color: var(--color-white);
        columns: 2;
    }
}
.MobileMenu-columnFooterContact {
    padding: 0 calc(var(--gutter) * 2.8);
    .MobileMenu-catList {
        padding: 0;
    }
    hr {
        border-bottom: 0;
        border-top: 1px solid var(--color-dark-gray);
        margin: var(--gutter);
    }
}
.MobileMenu-columnFooterProducts {
    display: flex;
    flex-wrap: wrap;
    padding: 0 calc(var(--gutter) / 2);
}

.MobileMenu-letterHeading {
    font-family: var(--font-family-canela-text);
    font-size: var(--font-size-canela-m);
    margin-top: var(--gutter);
    padding: var(--gutter);
    text-transform: uppercase;
    width: 100%;

    &:first-child {
        margin-top: 0;
    }
}

.MobileMenu-catList {
    line-height: 1.5;
    list-style: none;
    margin: 0;
    padding: 0 calc(var(--gutter) * 2.8);
}
.MobileMenu-catList--topLevel {
    margin-top: calc(var(--gutter) * 2);
}
.MobileMenu-catListGroup {
    align-items: center;
    background-image: linear-gradient(
        to top,
        var(--color-medium-gray),
        var(--color-medium-gray)
    );
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: calc(100% - var(--gutter) * 2) 1px;
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--gutter) / 2);

    i {
        padding: 0 var(--gutter);
    }
}
.MobileMenu-catListItem {
    font-size: var(--font-size-tt-commons-m);
    padding: var(--gutter);
    text-transform: uppercase;

    .MobileMenu-catList--topLevel & {
        animation: 0.45s linear calc(var(--index, 0) * 0.05s) backwards fade-in;
    }

    i {
        margin-left: 0.5em;
        position: relative;
        top: 0.1em;
    }
}
.MobileMenu-catListItem--heading {
    font-family: var(--font-family-tt-commons-demibold);
}
.MobileMenu-catListItem--topLevelHeading {
    background-image: linear-gradient(
        to top,
        var(--color-medium-gray),
        var(--color-medium-gray)
    );
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: calc(100% - var(--gutter) * 2) 1px;
    font-family: var(--font-family-tt-commons-demibold);
    margin-bottom: calc(var(--gutter) / 2);
}
.MobileMenu-catListItem--viewAll {
    color: var(--color-dark-gray);
}

.MobileMenu-featuredLink {
    display: block;
    padding: var(--gutter) calc(var(--gutter) / 2);
    width: 50%;
}
.MobileMenu-featuredLinkImage {
    opacity: 0;
    transition: opacity 0.3s linear;

    &.is-loaded {
        opacity: 1;
    }
}
.MobileMenu-featuredLinkName {
    margin-top: 1em;
    text-transform: uppercase;
}
