/** Opinionated LL Reset */

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol {
    margin: 0;
}
body,
input,
textarea,
button {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
    word-wrap: break-word;
}
input,
textarea,
button {
    -webkit-appearance: none;
    border-radius: 0;
}
details,
summary {
    outline: none;
}
summary {
    display: block;
}
summary::-webkit-details-marker {
    display: none;
}
