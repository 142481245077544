/** @define Popup; weak */

.Popup-wrapper {
    display: flex;
    height: 100%;
    inset: 0;
    outline: none;
    padding: var(--gutter);
    pointer-events: none;
    position: absolute;
    width: 100%;
}

.Popup-modal {
    background-color: var(--color-white);
    margin: auto;
    max-width: 60em;
    padding: var(--gutter);
    pointer-events: all;
    position: relative;
    width: 100%;
}
.Popup-modal--locale {
    max-width: 45em;
}

.Popup-close {
    padding: var(--gutter);
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    z-index: 2;

    span {
        @include visually-hidden;
    }

    i {
        font-size: 1.5em;
    }
}

.Popup-text {
    font-size: var(--font-size-canela-s);
    margin: 0 auto;
    margin-top: calc(var(--gutter) * 2);
    max-width: 42em;
    text-align: center;

    .Popup-modal--locale & {
        max-width: 20em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: var(--font-family-tt-commons-medium);
        font-size: var(--font-size-tt-commons-xl);
        letter-spacing: var(--letter-spacing-tt-commons);
        line-height: 1;
        margin: 1.5rem 0;
        text-transform: uppercase;
    }
}

.Popup-formWrapper {
    margin-top: calc(var(--gutter) * 3);
}

.Popup-buttons {
    display: flex;
    gap: var(--gutter);
    justify-content: center;
    margin: calc(var(--gutter) * 2) 0;
}
.Popup-button {
    align-items: center;
    background-color: var(--color-medium-gray);
    border: 0;
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    height: calc(var(--row-height) * 1.5);
    justify-content: center;
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: 0 var(--gutter);
    text-transform: uppercase;
    white-space: nowrap;

    @media (hover) {
        &:hover {
            background-color: var(--color-medium-gray-hover);
        }
    }

    img {
        display: block;
        height: 1.5em;
        margin-right: 1em;
        width: auto;
    }
}
