.Breadcrumbs {
    align-items: center;
    background: var(--color-white);
    border-bottom: 1px solid var(--color-medium-gray);
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    height: calc(var(--row-height) * 1.5);
    letter-spacing: var(--letter-spacing-tt-commons);
    position: sticky;
    text-transform: uppercase;
    top: var(--header-height);
    transform: translateZ(0px);
    width: 100%;
    z-index: 2;
}
.Breadcrumbs-content {
    align-items: center;
    display: flex;
    flex: 1;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow: auto;
    padding: 0 var(--gutter);
    position: relative;
    white-space: nowrap;

    i {
        margin: 0 var(--gutter);
    }
}
.Breadcrumbs-content--withSort {
    &:after {
        content: "";
        display: block;
        flex-shrink: 0;
        height: 100%;
        width: 2em;
    }
}
.Breadcrumbs-separator {
    flex-shrink: 0;
    width: calc(var(--gutter) * 2);
}
.Breadcrumbs-item {
    a {
        // When .Breadcrumbs-item is used as a subnav link, it is automatically
        // scrolled into view. We use `scroll-margin` to make it so that the
        // link doens't just align to the very edge of the viewport
        scroll-margin-inline: 25vw;
    }
    a:hover,
    a.is-active {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }
}
.Breadcrumbs-item--bold {
    font-family: var(--font-family-tt-commons-demibold);
}
.Breadcrumbs-item--subnav {
    & + & {
        margin-left: var(--gutter);
    }
}

.Breadcrumbs-sortButton {
    align-items: center;
    align-self: stretch;
    display: flex;
    padding: 0 var(--gutter);
    position: relative;

    @media (min-width: $tablet-width) {
        min-width: 7.25rem;
    }

    &:before {
        background-image: linear-gradient(to left, white, hsla(0, 0%, 100%, 0));
        bottom: 0;
        content: "";
        display: block;
        pointer-events: none;
        position: absolute;
        right: 100%;
        top: 0;
        width: 2em;
    }

    &:after {
        background-color: var(--color-medium-gray);
        content: "";
        display: block;
        height: calc(var(--row-height) * 0.75);
        left: 1px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
    }

    i {
        font-size: 0.9em;
    }
}
.Breadcrumbs-sortSelect {
    -webkit-appearance: menulist-button;
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    text-transform: none;
    top: 0;
    width: 100%;
}
