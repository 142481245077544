/** @define Product; weak */

.Product {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;

    @media (min-width: $desktop-width) {
        background-color: var(--color-light-gray);
        flex-direction: row;
        flex-wrap: wrap;
        min-height: calc(100vh - 9rem);
    }
}

.Product--noRelated {
    margin-bottom: calc(var(--row-height) * 2);

    @media (min-width: $desktop-width) {
        border-bottom: 1px solid var(--color-medium-gray);
    }
}

.Product-openImageZoom,
.Product-closeImageZoom {
    cursor: pointer;
    padding: var(--gutter);
    z-index: 1;

    i {
        font-size: 1.5rem;
    }
}

.Product-openImageZoom {
    padding: 1.5rem;
    position: absolute;

    body.is-zooming & {
        display: none;
    }
}

.Product-closeImageZoom {
    left: 0;
    position: fixed;
    top: 0;
}

.Product-images {
    @media (max-width: ($desktop-width - 1px)) {
        border-bottom: 1px solid var(--color-medium-gray);
        display: flex;
        overflow: auto;
    }

    @media (min-width: $desktop-width) {
        align-self: flex-start;
        width: 50%;
    }

    @media (min-width: 1300px) {
        width: 60%;
    }

    body.is-zooming & {
        background-color: var(--color-light-gray);
        display: block;
        height: 100%;
        left: 0;
        overflow: auto;
        padding: calc(var(--gutter) * 2);
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 5;
    }
}

.Product-image {
    flex: none;
    position: relative;
    width: 100%;

    @media (min-width: $tablet-width) and (max-width: ($desktop-width - 1px)) {
        width: 75%;
    }

    body.is-zooming & + & {
        margin-top: calc(var(--gutter) * 2);
    }

    &.is-landscape {
        @media (max-width: ($tablet-width - 1px)) {
            width: 200%;
        }

        @media (min-width: $tablet-width) and (max-width: ($desktop-width - 1px)) {
            width: 150%;
        }
    }

    body.is-zooming & {
        width: 100%;
    }

    img {
        opacity: 0;
        transition: opacity 0.3s linear;

        &.is-loaded {
            opacity: 1;
        }
    }
}

.Product-image--video {
    @media (max-width: ($desktop-width - 1px)) {
        width: auto;

        video {
            display: block;
            height: 100%;
            width: auto;
        }

        body.is-zooming & video {
            height: auto;
            width: 100%;
        }
    }
}

.Product-contentColumn {
    @media (min-width: $desktop-width) {
        background-color: var(--color-white);
        border-left: 1px solid var(--color-medium-gray);
        flex: 1;
    }
}

.Product-content {
    display: flex;
    flex-direction: column;

    @media (min-width: $desktop-width) {
        height: 100%;
        min-height: calc(100vh - var(--header-height) * 2);
    }
}

.Product-contentInner {
    padding: var(--gutter);
    padding-bottom: 0;
    width: 100%;

    @media (min-width: $tablet-width) {
        padding-bottom: calc(var(--gutter) * 4);
        padding-top: calc(var(--gutter) * 2);
        position: sticky;
        top: calc(var(--header-height) * 2);
    }
}

.Product-contentInner--alignTop {
    @media (min-width: $tablet-width) {
        top: var(--header-height);
    }
}

.Product-brandRow {
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-l);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-bottom: calc(var(--gutter) / 2);
    text-transform: uppercase;
}

.Product-titleRow {
    font-family: var(--font-family-canela-deck);
    margin-bottom: calc(var(--gutter) * 2);

    @media (min-width: $tablet-width) {
        margin-bottom: calc(var(--gutter) * 3);
    }
}

.Product-title {
    font-size: var(--font-size-canela-xl);
    line-height: 1.15;
    margin: 0;
}

.Product-price {
    display: inline-flex;
    font-size: var(--font-size-canela-l);
    margin-top: calc(var(--gutter) * 0.75);

    del {
        color: var(--color-dark-gray);
        margin-right: var(--gutter);
        position: relative;
        text-decoration: none;

        img {
            height: 200%;
            left: -1rem;
            object-fit: cover;
            pointer-events: none;
            position: absolute;
            top: -50%;
            width: calc(100% + 1rem);
        }
    }

    ins {
        text-decoration: none;
    }
}

.Product-lowestPrice {
    align-items: center;
    color: var(--color-dark-gray);
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-top: 1rem;
    text-transform: uppercase;
}

.Product-availability,
.Product-lowestPrice {
    align-items: center;
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.Product-availabilityIcon {
    --size: 0.85em;

    background-color: green;
    border-radius: var(--size);
    height: var(--size);
    margin-right: 0.75em;
    width: var(--size);
}

.Product-availabilityInfoButton {
    margin-left: 1em;

    i {
        display: block;
    }
}

.Product-variantSelect {
    cursor: pointer;
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-bottom: var(--gutter);
    position: relative;
    text-transform: uppercase;
}

.Product-variantSelectSelection {
    border: 1px solid var(--color-medium-gray);
    width: 100%;

    @media (hover) {
        &:hover {
            border-color: var(--color-medium-gray-hover);
        }
    }
}

.Product-variantSelectIcon {
    position: absolute;
    right: var(--gutter);
    top: 50%;
    transform: translateY(-50%);

    .Product-variantSelect.is-open & {
        transform: translateY(-50%) rotate(90deg);
    }
}

.Product-variantSelectItem {
    align-items: center;
    display: flex;
    height: calc(var(--row-height) * 1.5);
    width: 100%;
}

.Product-variantSelectImage {
    background-color: var(--color-medium-gray);
    border-right: 1px solid var(--color-medium-gray);
    height: 100%;
    position: relative;
    width: 3rem;

    @media (min-width: $tablet-width) {
        width: calc(var(--row-height) * 1.5);
    }
}

.Product-variantSelectText {
    flex: 1;
    padding: 0 calc(var(--gutter) * 2.5) 0 var(--gutter);
}

.Product-variantSelectDropdown {
    background: var(--color-white);
    border: 1px solid var(--color-medium-gray);
    border-top: 0;
    display: none;
    left: 0;
    list-style: none;
    margin: 0;
    max-height: 25rem;
    overflow: auto;
    padding: 0;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 2;

    .Product-variantSelect.is-open & {
        display: block;
    }
}

.Product-variantSelectDropdownItem {
    & + & {
        border-top: 1px solid var(--color-medium-gray);
    }

    &:hover,
    &.is-selected {
        background: var(--color-light-gray);
    }
}

.Product-paymentFields {
    display: grid;
    gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
    margin-top: var(--gutter);

    @media (max-width: ($tablet-width - 1px)) {
        margin-bottom: calc(var(--gutter) * 4);
    }
}

.Product-addToCartForm {
    align-items: stretch;
    display: flex;
    margin-bottom: var(--gutter);
    width: 100%;

    &.is-inactive {
        opacity: 0.5;
    }
}

.Product-additionalForm {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--gutter);
    width: 100%;
}

.Product-quantity {
    border: 1px solid var(--color-medium-gray);
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    justify-self: start;
    margin-right: var(--gutter);
}

.Product-quantityButton,
.Product-quantityInput {
    --size: calc(var(--row-height) * 1.5);

    font: inherit;
    height: calc(var(--size) - 2px);
    line-height: 1;
    text-align: center;
    width: calc(var(--size) - 2px);
}

.Product-quantityInput {
    border: 0;
    border-left: 1px solid var(--color-medium-gray);
    border-right: 1px solid var(--color-medium-gray);

    &:disabled {
        background: white;
    }
}

.Product-quantityButton {
    &:focus,
    &:enabled:hover {
        outline: 1px solid var(--color-medium-gray-hover);
        z-index: 1;
    }

    &:disabled {
        color: var(--color-dark-gray);
    }
}

.Product-addToCartButton,
.Product-paymentButton,
.Product-orderFromSupportButton,
.Product-outOfStockButton {
    background-color: var(--color-black);
    border: 0;
    color: var(--color-white);
    flex: 1;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-l);
    height: calc(var(--row-height) * 1.5);
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: 0 var(--gutter);
    text-transform: uppercase;
}

.Product-orderFromSupportButton {
    background-color: transparent;
    border: 1px solid var(--color-medium-gray);
    color: var(--color-black);
}

.Product-addToCartButton {
    align-items: center;
    display: flex;
    justify-content: center;

    &:disabled {
        background-color: var(--color-dark-gray);
    }
}

.Product-paymentButton {
    grid-column: span 2;
}

.Product-outOfStockText,
.Product-outOfStockResponse {
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    text-transform: uppercase;
    width: 100%;
}

.Product-outOfStockText {
    margin-bottom: var(--gutter);
}

.Product-outOfStockResponse {
    margin-top: var(--gutter);
}

.Product-outOfStockLabel {
    align-items: center;
    border: 1px solid var(--color-medium-gray);
    cursor: text;
    display: grid;
    letter-spacing: var(--letter-spacing-tt-commons);
    min-height: calc(var(--row-height) * 1.5);
    padding: 0 var(--gutter);
    position: relative;
    width: calc(75% - var(--gutter));

    &.is-submitting {
        opacity: 0.5;
    }
}

.Product-outOfStockInput {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    grid-area: 1 / 1;
    outline: 0;
    padding: 0;
    width: 100%;
}

.Product-outOfStockLabelText {
    display: block;
    font-family: var(--font-family-tt-commons-regular);
    grid-area: 1 / 1;
    pointer-events: none;
    text-transform: uppercase;

    .Product-outOfStockInput:focus + & {
        opacity: 0.5;
    }
}

.Product-outOfStockButton {
    flex: none;
    margin-left: var(--gutter);
    width: 25%;

    &:disabled {
        opacity: 0.5;
    }
}

.Product-tabsWrapper {
    --summary-height: calc(var(--row-height) * 1.5);

    margin-top: calc(var(--gutter) * 2);

    @media (max-width: ($tablet-width - 1px)) {
        margin-bottom: var(--summary-height);
        margin-left: calc(var(--gutter) * -1);
        margin-right: calc(var(--gutter) * -1);
    }

    @media (min-width: $tablet-width) {
        padding-top: calc(var(--summary-height) + 2px);
    }
}

.Product-tabs {
    position: relative;
}

.Product-tab {
}

.Product-tabSummary {
    background-color: var(--color-white);
    cursor: pointer;
    display: block;
    font-family: var(--font-family-tt-commons-demibold);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: var(--letter-spacing-tt-commons);
    list-style-type: none;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: $tablet-width) {
        bottom: 100%;
        left: calc(100% / var(--tab-count) * var(--tab-index));
        position: absolute;
        width: calc(100% / var(--tab-count));

        .Product-tab[open] & {
            background-color: var(--color-light-gray);
        }
    }

    &::-webkit-details-marker {
        display: none;
    }

    > div {
        align-items: center;
        display: flex;
        height: var(--summary-height);
        justify-content: space-between;
        padding: 0 var(--gutter);

        @media (max-width: ($tablet-width - 1px)) {
            border-bottom: 1px solid var(--color-medium-gray);
        }

        @media (min-width: $tablet-width) {
            border: 1px solid var(--color-medium-gray);
            border-left: 0;
        }

        .Product-tab:first-child & {
            @media (max-width: ($tablet-width - 1px)) {
                border-top: 1px solid var(--color-medium-gray);
            }

            @media (min-width: $tablet-width) {
                border-left: 1px solid var(--color-medium-gray);
            }
        }
    }

    .Product-tab[open] & i {
        transform: rotate(90deg);
    }
}

.Product-tabContent {
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: 0.04em;
    line-height: 1.4;
    min-height: 10em;
    overflow: hidden;

    @media (max-width: ($tablet-width - 1px)) {
        border-bottom: 1px solid var(--color-medium-gray);
        border-top: 0;
    }

    @media (min-width: $tablet-width) {
        border: 1px solid var(--color-medium-gray);
        border-top: 0;
    }

    > div {
        max-width: 55rem;
        padding: var(--gutter);
    }

    h4 {
        font-weight: 600;
    }

    a {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }

    p:last-child {
        margin-bottom: 0;
    }

    dl > div {
        display: flex;
    }

    dl > div {
        margin-bottom: var(--gutter);
    }

    dt {
        margin-right: 1em;
        min-width: 20%;

        @media (min-width: $tablet-width) {
            min-width: 18%;
        }
    }
}
