/** @define Blocks; weak */

.Blocks {
    box-shadow: 0 -1px 0 var(--color-medium-gray) inset;
    display: grid;
    transition: padding-top 0.3s ease-out;

    &:last-child {
        box-shadow: none;
    }

    &:empty {
        display: none;
    }
}

.Blocks--1 {
    min-height: calc(100vh - 3rem);

    .Block:before {
        display: none;
    }

    &.Blocks--alternative {
        min-height: auto;
    }
}

.Blocks--2 {
    grid-template-columns: 1fr;

    &.Blocks--alt {
        .Block:before {
            padding-top: 62.5%;
        }
    }
}

.Blocks--3 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    &.Blocks--auto,
    &.Blocks--alt {
        grid-auto-flow: column;
    }
}

.Blocks--4 {
    grid-template-columns: 1fr;

    &.Blocks--auto,
    &.Blocks--alt {
        grid-template-rows: 1fr;
    }
}

.Blocks--5 {
    &.Blocks--unison {
        grid-template-columns: 1fr;
    }

    &.Blocks--auto,
    &.Blocks--alt {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

.Blocks--journal {
    grid-template-columns: 1fr;
}

.Blocks--bordered {
    background-color: var(--color-medium-gray);
    box-shadow: 0 -1px 0 var(--color-medium-gray);
    gap: 1px;
    position: relative;

    &:last-child {
        box-shadow: 0 -1px 0 var(--color-medium-gray);
    }
}

.Blocks--adaptToContent {
    min-height: auto;
}

@media (min-width: $tablet-width) and (max-width: ($desktop-width - 1px)) {
    .Blocks--2 {
        grid-template-columns: calc(50% - 1px) 1fr;
    }

    .Blocks--3 {
        grid-template-columns: repeat(2, calc(100% / 3 - 1px)) 1fr;
    }

    .Blocks--4 {
        grid-template-columns: calc(50% - 1px) 1fr;

        &.Blocks--auto,
        &.Blocks--alt {
            grid-template-rows: calc(50% - 1px) 1fr;
        }
    }

    .Blocks--5 {
        &.Blocks--unison {
            grid-template-columns: calc(50% - 1px) 1fr;
        }

        &.Blocks--auto,
        &.Blocks--alt {
            grid-template-columns: calc(50% - 1px) 1fr;
            grid-template-rows: 1fr;
        }
    }

    .Blocks--journal {
        grid-template-columns: calc(50% - 1px) 1fr;
    }
}

@media (min-width: $desktop-width) {
    .Blocks--2 {
        grid-template-columns: calc(50% - 1px) 1fr;
    }

    .Blocks--3 {
        grid-template-columns: calc(50% - 1px) 1fr;
        grid-template-rows: calc(50% - 1px) 1fr;

        &.Blocks--unison {
            grid-template-columns: repeat(2, calc(100% / 3 - 1px)) 1fr;
            grid-template-rows: 1fr;
        }
    }

    .Blocks--4 {
        grid-auto-flow: column;
        grid-template-columns: repeat(3, calc(100% / 4 - 1px)) 1fr;

        &.Blocks--auto,
        &.Blocks--alt {
            grid-template-rows: calc(50% - 1px) 1fr;
        }
    }

    .Blocks--5 {
        grid-auto-flow: column;

        &.Blocks--unison {
            grid-template-columns: repeat(3, calc(100% / 4 - 1px)) 1fr;
        }

        &.Blocks--auto,
        &.Blocks--alt {
            grid-template-columns: repeat(3, calc(100% / 4 - 1px)) 1fr;
            grid-template-rows: calc(50% - 1px) 1fr;
        }
    }

    .Blocks--journal {
        grid-template-columns: repeat(3, calc(100% / 4 - 1px)) 1fr;
    }
}

.Blocks-newsletter {
    margin: var(--gutter) 0;
    width: 100%;

    > * {
        font-size: var(--font-size-tt-commons-m);
        width: 100%;
    }
}
