/** @define Footer; weak */

.Footer {
    --border-color: var(--color-medium-gray);

    background-color: var(--color-medium-gray-hover);
    display: grid;
    grid-template-areas: "left" "right" "center";
    grid-template-columns: 100%;

    @media (min-width: $mobile-width) {
        display: grid;
        grid-template-areas: "left right" "center center";
        grid-template-columns: 50% 50%;
    }

    @media (min-width: $desktop-width) {
        grid-template-areas: "left center right";
        grid-template-columns: 25% 50% 25%;
    }
}

.Footer-leftColumn {
    border-right: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    grid-area: left;
    padding: var(--gutter) calc(var(--gutter) / 2);

    @media (min-width: $desktop-width) {
        padding: var(--gutter) 0;
    }
}

.Footer-leftColumnGrid {
}

.Footer-linkList {
    padding: var(--gutter) calc(var(--gutter) / 2);
    width: 50%;

    @media (min-width: $desktop-width) {
        padding: var(--gutter);
    }
}

.Footer-text {
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);

    .--centered & {
        text-align: center;
    }
}

.Footer-text--noMargin {
    > :last-child {
        margin-bottom: 0;
    }
}

.Footer-userControls {
    border-top: 1px solid var(--border-color);
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: var(--gutter);
    text-transform: uppercase;

    @media (max-width: ($desktop-width - 1px)) {
        margin-left: calc(var(--gutter) / -2);
        margin-right: calc(var(--gutter) / -2);
        padding-top: calc(var(--gutter) * 2);
    }
}

.Footer-langLinks {
    padding-right: 1em;

    a.is-active,
    a:hover {
        background-image: linear-gradient(
            to top,
            hsl(0, 0%, 18%),
            hsl(0, 0%, 18%)
        );
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;

        .MobileMenu-catList & {
            background-image: linear-gradient(
                to top,
                hsl(0, 0%, 100%),
                hsl(0, 100%, 99%)
            );
        }
    }
}

.Footer-currencyPicker {
    align-items: center;
    border-left: 1px solid var(--border-color);
    display: flex;
    margin-left: 1em;

    &:after {
        --size: 0.25em;

        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: calc(var(--size) * 1.35) solid currentColor;
        content: "";
        height: 0;
        left: -0.5em;
        pointer-events: none;
        position: relative;
        width: 0;
    }
}

.Footer-currencyPickerDropdown {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0 0.5em;
}

.Footer-centerColumn {
    align-items: center;
    border-right: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
    display: flex;
    flex-direction: column;
    grid-area: center;
    justify-content: space-between;
    padding: calc(var(--gutter) * 2) var(--gutter);
}

.Footer-newsletterForm {
    max-width: 50em;
    width: 100%;

    &.--centered {
        margin: 0 auto;
    }
}

.Footer-newsletterControls {
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-top: 1em;
    position: relative;
}

.Footer-newsletterLabel {
    @include visually-hidden;
}

.Footer-newsletterInputWrapper {
    flex: 1;
}

.Footer-newsletterInput {
    background: hsl(0, 0%, 98%);
    border: 0;
    color: var(--color-black);
    height: calc(var(--row-height) * 1.5);
    outline: 0;
    padding: 0 0.75em;
    width: 100%;
}

.Footer-newsletterButton {
    background: hsl(0, 0%, 12%);
    color: var(--color-white);
    flex-shrink: 0;
    min-width: 8em;
    padding: 0 var(--gutter);
    text-transform: uppercase;
}

.Footer-logoWrapper {
    margin-top: calc(var(--gutter) * 2);
}

.Footer-logo {
    margin: 0 auto 1em;
    width: 8em;
}

.Footer-rightColumn {
    border-top: 1px solid var(--border-color);
    grid-area: right;
    padding: calc(var(--gutter) * 2) var(--gutter);
}

.Footer-contact {
}
