/** @define ContactForm; weak */

.ContactForm {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gutter);
}

.ContactForm-label {
    border: 1px solid var(--color-medium-gray);
    display: block;
    letter-spacing: var(--letter-spacing-tt-commons);
    min-height: calc(var(--row-height) * 1.75 + var(--gutter) * 0.25);
    padding: calc(var(--gutter) * 0.875) var(--gutter);
    position: relative;
    width: 100%;

    &.is-active {
        border-color: var(--color-dark-gray);
    }

    .ContactForm.is-submitting &,
    .ContactForm.is-submitted & {
        opacity: 0.5;
    }
}
.ContactForm-label--half {
    @media (min-width: $mobile-width) {
        width: calc(50% - var(--gutter) / 2);
    }
}
.ContactForm-labelText {
    display: block;
    font-family: var(--font-family-tt-commons-regular);
    margin-bottom: calc(var(--gutter) * 0.25);
    padding: 0;
    text-transform: uppercase;
}

.ContactForm-input,
.ContactForm-textarea,
.ContactForm-select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;
    width: 100%;
}
.ContactForm-textarea {
    max-height: 12em;
    min-height: 6em;
    resize: vertical;
}
.ContactForm-fauxSelect {
    display: flex;
    justify-content: space-between;
}
.ContactForm-select {
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.ContactForm-buttons {
    display: flex;
    gap: var(--gutter);
    justify-content: center;
    margin: calc(var(--gutter) * 2) 0;
}
.ContactForm-button {
    align-items: center;
    background-color: var(--color-medium-gray);
    border: 0;
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-l);
    height: calc(var(--row-height) * 1.5);
    justify-content: center;
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: 0 var(--gutter);
    text-transform: uppercase;
    width: 100%;

    @media (hover) {
        &:enabled:hover {
            background-color: var(--color-medium-gray-hover);
        }
    }

    .ContactForm.is-submitting &,
    .ContactForm.is-submitted & {
        opacity: 0.5;
    }
}
.ContactForm-response {
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}
