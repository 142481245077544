/** @define ProductGrid; weak */

.ProductGrid {
}

.ProductGrid--borderTop {
    border-top: 1px solid var(--color-medium-gray);
}

.ProductGrid--related {
    margin-bottom: calc(var(--row-height) * 2);
}

.ProductGrid--module {
    margin: calc(var(--row-height) * 2) 0;
}

.ProductGrid-header {
    align-items: center;
    border-bottom: 1px solid var(--color-medium-gray);
    box-shadow: 0 1px 0 var(--color-medium-gray) inset;
    display: flex;
    justify-content: space-between;
    min-height: calc(var(--row-height) * 2);
    padding: var(--gutter);
}

.ProductGrid-title {
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-l);
    letter-spacing: var(--letter-spacing-tt-commons);
    text-transform: uppercase;
}

.ProductGrid-headerLink {
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-top: 0.1em;
    text-transform: uppercase;

    &:hover {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }
}

.ProductGrid-masthead {
    align-items: flex-end;
    border-bottom: 1px solid var(--color-medium-gray);
    display: flex;
    flex-wrap: wrap;
    gap: var(--gutter);
    justify-content: space-between;
    min-height: calc(var(--row-height) * 1.5);
    padding: var(--gutter);
}

.ProductGrid-masthead--noDesc {
    align-items: center;
}

.ProductGrid-description {
    @media (min-width: $tablet-width) {
        max-width: 40em;
        width: 50%;
    }
}

.ProductGrid-descriptionTitle {
    font-family: var(--font-family-canela-deck);
    font-size: var(--font-size-canela-m);
}

.ProductGrid-descriptionText {
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: 0.04em;
    line-height: 1.4;
    margin-top: 1rem;

    &:not(.is-expanded) {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }

    p {
        display: inline; // fix line clamp in Safari
    }

    a {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }
}

.ProductGrid-descriptionButton {
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-bottom: 1em;
    margin-top: 0.4em;
    text-transform: uppercase;

    @media (min-width: $tablet-width) {
        margin-bottom: 0.5em;
        margin-top: 0.25em;
    }
}

.ProductGrid-items {
    background-color: var(--color-light-gray);
    box-shadow: 0 -1px 0 var(--color-medium-gray) inset;
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: -1px;
    overflow: hidden;

    &.is-sorted {
        grid-auto-flow: initial;
    }

    @media (min-width: $desktop-width) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.ProductGrid-items--horizontal {
    display: block;

    @media (max-width: ($desktop-width - 1px)) {
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
    }

    > div {
        outline: 0;
        width: 100%;
    }

    > div.is-dragging {
        pointer-events: none;
    }
}

.ProductGrid-pagination {
    display: flex;
    justify-content: center;
    padding: calc(var(--gutter) * 3) var(--gutter);
}

.ProductGrid-paginationButton {
    align-items: center;
    background-color: var(--color-black);
    border: 0;
    color: var(--color-white);
    display: inline-flex;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    height: calc(var(--row-height) * 1.5);
    justify-content: center;
    letter-spacing: var(--letter-spacing-tt-commons);
    min-width: 10em;
    padding: 0 var(--gutter);
    text-transform: uppercase;

    &.is-disabled,
    &:disabled {
        opacity: 0.65;
        pointer-events: none;
    }

    @media (min-width: $tablet-width) {
        font-size: var(--font-size-tt-commons-l);
        min-width: 8em;
    }

    &.is-hidden {
        display: none;
    }

    & + & {
        margin-left: var(--gutter);
    }
}

.ProductGrid-item {
    border-bottom: 1px solid var(--color-medium-gray);
    border-left: 1px solid transparent;
    box-shadow: 1px 0 0 var(--color-medium-gray);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;

    .ProductGrid-items--horizontal & {
        flex-shrink: 0;
        min-height: 100%;
        width: 20%;

        @media (max-width: ($desktop-width - 1px)) {
            width: 45%;
        }
    }

    &.is-landscape {
        grid-column: span 2;
    }

    .ProductGrid-items--horizontal &.is-landscape {
        width: 40%;

        @media (max-width: ($desktop-width - 1px)) {
            width: 90%;
        }
    }
}

.ProductGrid-itemImageWrapper {
    overflow: hidden;
    position: relative;
}

.ProductGrid-itemImageWrapper--empty {
    aspect-ratio: 3/4;
}

.ProductGrid-itemImage {
    opacity: 0;
    transition: opacity 0.2s linear;

    &.is-loaded {
        opacity: 1;
    }
}

.ProductGrid-itemHoverImage {
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.15s linear;

    @media (hover) {
        .ProductGrid-itemImageWrapper:hover & {
            opacity: 1;
        }
        &.is-video {
            .ProductGrid-itemImageWrapper:hover & {
                display: block;
            }
        }
    }

    video {
        height: 100%;
        object-fit: cover;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &.is-hidden {
        display: none;
    }
}

.ProductGrid-itemAttributes {
    bottom: calc(var(--gutter) / 2);
    font-family: var(--font-family-tt-commons-regular);
    letter-spacing: var(--letter-spacing-tt-commons);
    pointer-events: none;
    position: absolute;
    right: var(--gutter);
    text-align: right;
}

.ProductGrid-itemText {
    display: flex;
    flex: 1;
    position: relative;
}

.ProductGrid-itemDesc {
    background: var(--color-white);
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: var(--gutter);
    position: relative;
    width: 100%;
}

.ProductGrid-itemBrand {
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-bottom: calc(var(--gutter) / 2);
    margin-top: 0;
    min-height: calc(
        var(--font-size-tt-commons-base) * var(--line-height-regular)
    );
    text-transform: uppercase;
}

.ProductGrid-itemName,
.ProductGrid-itemPrice,
.ProductGrid-itemQuickBuy {
    font-family: var(--font-family-canela-deck);
    font-size: var(--font-size-canela-s);

    .ProductGrid-item--small & {
        font-size: var(--font-size-canela-xs);
    }
}

.ProductGrid-itemName {
    margin-bottom: var(--gutter);
    margin-top: 0;
}

.ProductGrid-itemPrice {
    display: inline-flex;
    margin-top: auto;

    > div {
        display: flex;
        max-width: 100%;
        white-space: nowrap;

        @media screen and (max-width: $mobile-width) {
            flex-flow: column;
        }
    }

    del {
        color: var(--color-dark-gray);
        flex-shrink: 1;
        margin-right: 0.75em;
        overflow: hidden;
        position: relative;
        text-decoration: none;
        text-overflow: ellipsis;

        @media screen and (max-width: $mobile-width) {
            font-size: 1.05rem;
        }
    }

    del img {
        height: 200%;
        left: -1rem;
        object-fit: cover;
        opacity: 0.6;
        pointer-events: none;
        position: absolute;
        top: -50%;
        width: calc(100% + 1rem);
    }

    ins {
        text-decoration: none;
    }
}

.ProductGrid-itemQuickBuy {
    bottom: var(--gutter);
    display: none;
    position: absolute;
    right: var(--gutter);

    &:hover {
        color: var(--color-dark-gray);
    }

    .Icon {
        font-size: 1.15em;
    }
}

.ProductGrid-itemStickers {
    display: flex;
    gap: 1em;
    pointer-events: none;
    position: absolute;
    right: var(--gutter);
    top: var(--gutter);
}

.ProductGrid-itemSticker {
    background-color: var(--color-light-gray);
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: 0.5em 0.75em;
    text-transform: uppercase;
}
