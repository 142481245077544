/** @define Block; weak */

.Block {
    background-color: var(--color-white);
    display: flex;
    position: relative;

    &.Block-lightColor {
        color: var(--color-white);
    }

    &.Block-darkColor {
        color: var(--color-black);
    }

    &.Block-portrait {
        &:before {
            content: "";
            padding-top: 125%;
        }
    }

    &.Block-landscape {
        &:before {
            content: "";
            padding-top: 62.5%;
        }
    }

    .Blocks--adaptToContent &:before {
        content: none;
    }
}

.Block-image {
    background-color: var(--color-medium-gray);
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: filter 0.2s linear;
    width: 100%;

    img,
    video {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    img {
        opacity: 0;
        transition: opacity 0.3s linear;

        &.is-loaded {
            opacity: 1;
        }
    }

    @media (hover) {
        .Block-cardHover:hover & {
            filter: brightness(0.75);
        }
    }
}

@media (min-width: $tablet-width) {
    .Block-image {
        &:has(.Image-mobile) {
            .Image-mobile {
                display: none !important;
            }
        }
    }
}

@media (max-width: $tablet-width - 1px) {
    .Block-image {
        &:has(.Image-mobile) {
            img:not(.Image-mobile) {
                display: none !important;
            }
        }
    }
}

.Block-readMoreWrap {
    max-height: 25vh;
    overflow: hidden;
    position: relative;

    &::after {
        bottom: 0;
        content: "";
        display: block;
        height: 10rem;
        left: 0;
        position: absolute;
        right: 0;
    }

    &.is-activated {
        &::after {
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.5) 100%
            );
        }
    }

    &.is-expanded {
        max-height: unset;

        &::after {
            background: transparent;
        }
    }
}

.Block-readMoreButton {
    align-items: center;
    background-color: var(--color-black);
    border: 0;
    bottom: var(--gutter);
    color: var(--color-white);
    display: inline-flex;
    font-family: var(--font-family-tt-commons-medium);
    justify-content: center;
    left: 50%;
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: var(--gutter) calc(var(--gutter) * 2);
    position: absolute;
    text-transform: uppercase;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 5;

    &.is-disabled,
    &:disabled {
        opacity: 0.65;
        pointer-events: none;
    }

    @media (min-width: $tablet-width) {
        font-size: var(--font-size-tt-commons-l);
        min-width: 8em;
    }
}

.Block-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: calc(var(--gutter) * 2);
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;

    &.left-align {
        text-align: left;
    }

    &.right-align {
        text-align: right;
    }

    .ContactForm {
        text-align: left;
    }

    &.no-pointers {
        pointer-events: none;
    }
}

.Block-text {
    width: 100%;

    &.small-max-width {
        max-width: 24rem;
    }

    &.normal-max-width {
        max-width: 42rem;
    }

    &.medium-max-width {
        max-width: 54rem;
    }

    &.large-max-width {
        max-width: 72rem;
    }
}

.Block-action,
.Block-title {
    bottom: calc(var(--gutter) * 1.25);
    font-family: var(--font-family-tt-commons-medium);
    font-size: 1.5rem;
    left: calc(var(--gutter) * 1.5);
    letter-spacing: 0.075em;
    position: absolute;
    text-transform: uppercase;

    .Blocks--4 .Block:not(.Block-double) &,
    .Blocks--5 .Block:not(.Block-double) & {
        font-size: 1.2rem;
    }
}

.Block-action {
    left: auto;
    right: calc(var(--gutter) * 1.5);
}

.Block-title {
    text-align: left;
    width: calc(100% - 6rem);
}

.Block-journal {
    background-color: var(--color-medium-gray);
    flex-direction: column;
    padding: var(--gutter);

    .Block-image {
        background-color: var(--color-white);
        position: relative;
    }

    .Block-content {
        align-content: space-between;
        background-color: var(--color-white);

        > * {
            flex-basis: 33.33%;
        }
    }

    h2 {
        margin-top: 0;
    }
}

.Journal-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Journal-excerpt {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media (min-width: $tablet-width) and (max-width: $desktop-width - 1px) {
    .Block {
        &.Block-portrait {
            .Blocks--3:not(.Blocks--unison) &,
            .Blocks--4:not(.Blocks--unison) & {
                grid-row: span 2;
            }

            &.Block-double {
                .Blocks--4 & {
                    grid-column: span 2;
                    grid-row: span 4;
                }

                .Blocks--5 & {
                    grid-column: span 2;
                    grid-row: span 2;
                }
            }
        }

        &.Block-landscape {
            &.Block-double {
                .Blocks--4:not(.Blocks--unison) & {
                    grid-column: span 2;
                }
            }
        }
    }
}

@media (min-width: $desktop-width) {
    .Block {
        &.Block-portrait {
            .Blocks--3:not(.Blocks--unison) &,
            .Blocks--4:not(.Blocks--unison) & {
                grid-row: span 2;
            }

            &.Block-double {
                .Blocks--4 & {
                    grid-column: span 2;
                    grid-row: span 4;
                }

                .Blocks--5 & {
                    grid-column: span 2;
                    grid-row: span 2;
                }
            }
        }

        &.Block-landscape {
            &.Block-double {
                .Blocks--4:not(.Blocks--unison) & {
                    grid-column: span 2;
                }
            }
        }
    }

    .Block-excerpt {
        bottom: 0;
        position: absolute;
    }

    .Block-journal {
        flex-direction: row;

        .Block-image {
            flex: 0 0 50%;
            height: auto;
            padding: 1rem;
            position: relative;
            width: auto;

            > * {
                margin: 3rem !important;
            }
        }

        .Block-content {
            height: auto;
            padding: 3rem;
        }

        &.flip {
            flex-direction: row-reverse;
        }

        &.no-border {
            padding: 0;
        }
    }
}
