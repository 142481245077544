@import "node_modules/normalize.css/normalize";
@import "node_modules/spin.js/spin";
@import "node_modules/flickity/css/flickity";
@import "_reset";
@import "_fonts";
@import "_vars";
@import "utilities";
@import "Block";
@import "Blocks";
@import "Breadcrumbs";
@import "Cart";
@import "Checkout";
@import "Footer";
@import "Header";
@import "Icon";
@import "MobileMenu";
@import "Panel";
@import "Popup";
@import "Product";
@import "ProductGrid";
@import "CustomerService";
@import "ContactForm";
@import "CookieNotice";
@import "CookieDeclaration";

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    99% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

// stylelint-disable-next-line
#__next {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
}

.Main {
    flex: 1;
}

.Main--checkout {
    align-items: center;
    background-color: var(--color-light-gray);
    display: flex;
    flex-direction: column;
}

.Main--stretch {
    background-color: var(--color-light-gray);

    @media (min-width: $tablet-width) {
        display: flex;
    }
}

html,
body {
    color: var(--color-black);
    font-family: var(--font-family-canela-text);
    font-size: var(--font-size-tt-commons-base);
    line-height: var(--line-height-regular);
}

body {
    &.is-zooming {
        overflow: hidden;
    }

    &.is-showingMenu,
    &.is-showingPopup {
        overflow: hidden;
    }
}

a {
    background-color: transparent;
    color: inherit;
    text-decoration: none;
    text-decoration-skip-ink: auto;
}

b,
strong {
    font-weight: bold;
}

button,
input,
select,
textarea {
    color: inherit;
    font: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
}

button {
    background: none;
    border: 0;
    padding: 0;

    &:enabled {
        cursor: pointer;
    }
}

dl,
dt,
dd {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font: inherit;
    margin: 0;
}

img,
video {
    display: block;
    height: auto;
    width: 100%;
}

input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

p {
    margin-bottom: 1em;
}