/** @define Cart; weak */

.Cart {
    --timing: 0.4s;
    --easing: cubic-bezier(0.48, 0.01, 0.43, 1);

    background-color: var(--color-white);
    bottom: 0;
    box-shadow: -1px 0 0 var(--color-medium-gray);
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-canela-deck);
    position: fixed;
    right: 0;
    top: var(--header-height, 3rem);
    transform: translateX(100%);
    transition: transform var(--timing) var(--easing),
        visibility var(--timing) step-end;
    visibility: hidden;
    width: calc(40% + 1px);
    z-index: 4;

    &.is-visible {
        transform: none;
        transition: transform var(--timing) var(--easing),
            visibility var(--timing) step-start;
        visibility: visible;
    }

    @media (max-width: ($tablet-width - 1px)) {
        left: 0;
        width: auto;
    }
}

.Cart--checkout {
    border-bottom: 1px solid var(--color-medium-gray);
    border-top: 1px solid var(--color-medium-gray);
    margin-bottom: var(--gutter);
    margin-top: var(--gutter);
    position: relative;
    top: 0;
    width: 100%;
    z-index: initial;

    @media (min-width: $tablet-width) {
        border-right: 1px solid var(--color-medium-gray);
        width: 66.666%;
    }

    @media (min-width: $desktop-width) {
        width: 50%;
    }
}

.Cart-backdrop {
    --timing: 0.4s;

    background-color: var(--color-black-opacity);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: var(--header-height, 3rem);
    transition: opacity var(--timing) linear, visibility var(--timing) step-end;
    visibility: hidden;
    z-index: 4;

    &.is-visible {
        opacity: 1;
        transition: opacity var(--timing) linear,
            visibility var(--timing) step-start;
        visibility: visible;
    }
}

.Cart-header {
    align-items: center;
    border-bottom: 1px solid var(--color-medium-gray);
    display: flex;
    height: calc(var(--row-height) * 1.75);
    padding: 0 var(--gutter);
}

.Cart-title {
    font-size: var(--font-size-canela-m);
    margin: 0;
}

.Cart-closeButton {
    font-size: 1.5em;
    margin-left: auto;
    padding: 0;

    .Cart--checkout & {
        display: none;
    }

    span {
        @include visually-hidden;
    }
}

.Cart-contentTop,
.Cart-contentMiddle,
.Cart-contentBottom {
    .Cart.is-loading & {
        opacity: 0.65;
    }
}

.Cart-contentTop {
    .Cart--checkout & {
        display: none;
    }
}

.Cart-contentMiddle {
    margin-bottom: auto;
    overflow: auto;
}

.Cart-newlyAdded {
    border-bottom: 1px solid var(--color-medium-gray);
    display: grid;
    grid-template-areas: "image info info" "image qty price";
    grid-template-columns: 25% 1fr min-content;
    grid-template-rows: 1fr min-content;
    padding: var(--gutter);

    @media (max-width: ($tablet-width - 1px)) {
        grid-template-areas:
            "image info info"
            "image info info"
            "image qty qty"
            "image price price";
        grid-template-columns: 33.333% 1fr min-content;
        grid-template-rows: 1fr min-content min-content min-content;
    }
}

.Cart-newlyAddedImageWrapper {
    align-self: start;
    border-right: 1px solid var(--color-medium-gray);
    grid-area: image;
    margin-right: var(--gutter);
    padding-right: var(--gutter);

    @media (max-width: ($tablet-width - 1px)) {
        margin-right: calc(var(--gutter) * 0.75);
        padding-right: calc(var(--gutter) * 0.75);
    }
}

.Cart-newlyAddedImage {
}

.Cart-newlyAddedInfo {
    grid-area: info;
}

.Cart-newlyAddedMeta {
    display: flex;
    justify-content: space-between;
    letter-spacing: var(--letter-spacing-tt-commons);
}

.Cart-newlyAddedBrand {
    font-family: var(--font-family-tt-commons-regular);
    margin-right: calc(var(--gutter) / 2);
    text-transform: uppercase;
}

.Cart-newlyAddedRemove {
    align-self: start;
    color: var(--color-dark-gray);
    font-family: var(--font-family-tt-commons-regular);
    text-transform: uppercase;

    &:hover {
        color: inherit;
    }
}

.Cart-newlyAddedTitle {
    font: inherit;
    font-size: var(--font-size-canela-s);
    margin: calc(var(--gutter) / 2) 0 0 0;
}

.Cart-newlyAddedShipping {
    align-items: center;
    display: flex;
    font-family: var(--font-family-tt-commons-regular);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin: calc(var(--gutter) / 2) 0;
    text-transform: uppercase;
}

.Cart-newlyAddedAvailabilityIcon {
    --size: 0.85em;

    background-color: green;
    border-radius: var(--size);
    height: var(--size);
    margin-right: 0.75em;
    width: var(--size);
}

.Cart-newlyAddedQuantity {
    border: 1px solid var(--color-medium-gray);
    display: flex;
    grid-area: qty;
    justify-self: start;
}

.Cart-newlyAddedQuantityButton,
.Cart-newlyAddedQuantityInput {
    --size: 2.5em;

    font: inherit;
    height: var(--size);
    line-height: 1;
    text-align: center;
    width: var(--size);

    @media (max-width: ($tablet-width - 1px)) {
        --size: 2.15em;
    }
}

.Cart-newlyAddedQuantityButton {
    &:focus,
    &:enabled:hover {
        outline: 1px solid var(--color-medium-gray-hover);
        z-index: 1;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.Cart-newlyAddedQuantityInput {
    border: 0;
    border-left: 1px solid var(--color-medium-gray);
    border-right: 1px solid var(--color-medium-gray);
}

.Cart-newlyAddedPrice {
    align-self: end;
    display: flex;
    grid-area: price;
    white-space: nowrap;

    @media (max-width: ($tablet-width - 1px)) {
        margin-top: calc(var(--gutter) / 2);
    }

    &.is-discounted span {
        color: var(--color-dark-gray);
        padding-right: var(--gutter);
        position: relative;

        img {
            height: 200%;
            left: 0;
            object-fit: cover;
            pointer-events: none;
            position: absolute;
            top: -50%;
            width: 100%;
        }
    }
}

.Cart-contentMiddle {
}

.Cart-items,
.Cart-items--maxHeight {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0 var(--gutter);
}

.Cart-items--maxHeight {
    max-height: 50vh;
    overflow-y: scroll;
}

.Cart-item {
    border-bottom: 1px solid var(--color-medium-gray);
    display: grid;
    grid-template-areas: "image meta" "image body";
    grid-template-columns: 25% 1fr;
    grid-template-rows: min-content 1fr;
    padding: var(--gutter) 0;

    @media (min-width: $tablet-width) {
        grid-template-columns: 20% 1fr;
    }

    @media (min-width: $desktop-width) {
        grid-template-columns: 12.5% 1fr;
    }
}

.Cart-item--shipping {
    border-bottom: 0;
    box-shadow: 0 -1px 0 var(--color-medium-gray);
    display: block;
}

.Cart-item--account {
    border-bottom: 0;

    & + & {
        border-top: 1px solid var(--color-medium-gray);
    }
}

.Cart-itemImageWrapper {
    border-right: 1px solid var(--color-medium-gray);
    grid-area: image;
    margin-right: var(--gutter);
    padding-right: var(--gutter);

    @media (max-width: ($tablet-width - 1px)) {
        margin-right: calc(var(--gutter) * 0.75);
        padding-right: calc(var(--gutter) * 0.75);
    }

    > a {
        aspect-ratio: 748 / 995;
        display: block;
    }
}

.Cart-itemMeta {
    display: flex;
    font-family: var(--font-family-tt-commons-regular);
    grid-area: meta;
    letter-spacing: var(--letter-spacing-tt-commons);
    text-transform: uppercase;

    .Cart--checkout & {
        margin-bottom: 0.2rem;
    }
}

.Cart-itemMeta--start,
.Cart-itemBody--start {
    grid-column: 1 / 3;
}

.Cart-itemBrand {
    border-right: 1px solid var(--color-medium-gray);
    margin-right: calc(var(--gutter) / 2);
    padding-right: calc(var(--gutter) / 2);

    &:last-child {
        border-right: 0;
    }
}

.Cart-itemQuantity {
    display: flex;
}

.Cart-itemQuantityButton {
    text-align: center;

    &:disabled {
        color: var(--color-dark-gray);
    }
}

.Cart-itemQuantityNumber {
    padding: 0 calc(var(--gutter) / 2);
}

.Cart-itemNoMore {
    display: none;
    margin-left: var(--gutter);
}

.Cart-itemRemove {
    color: var(--color-dark-gray);
    margin-left: auto;
    padding-bottom: 0.1em;
    text-transform: uppercase;

    &:hover {
        color: inherit;
    }
}

.Cart-itemBody,
.Cart-itemBody--start {
    display: flex;
    grid-area: body;
    justify-content: space-between;
}

.Cart-itemBody--start {
    grid-column: 1 / 3;
}

.Cart-itemBody--checkbox {
    align-items: center;
    justify-content: flex-start;
}

.Cart-itemTitle {
    font: inherit;
    font-size: var(--font-size-canela-s);
    margin: 0;
    padding-right: var(--gutter);

    .Cart--checkout & {
        font-family: var(--font-family-tt-commons-medium);
        font-size: var(--font-size-tt-commons-m);
        letter-spacing: var(--letter-spacing-tt-commons);
        line-height: 1;
        text-transform: uppercase;
    }

    .Cart--checkout .Cart-item--shipping & {
        font-family: var(--font-family-tt-commons-regular);
    }
}

.Cart-itemTitle--smaller {
    .Cart--checkout & {
        font-size: 1rem;
    }
}

.Cart-itemAvailability {
    color: var(--color-dark-gray);
    font-family: var(--font-family-tt-commons-regular);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-top: 0.2rem;
    text-transform: uppercase;
}

.Cart-itemPrice {
    align-items: end;
    align-self: start;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-canela-s);
    grid-area: price;
    white-space: nowrap;

    .Cart--checkout & {
        font-family: var(--font-family-tt-commons-regular);
        font-size: var(--font-size-tt-commons-m);
        letter-spacing: var(--letter-spacing-tt-commons);
        line-height: 1;
        text-transform: uppercase;
    }

    &.Cart-itemPrice--discount {
        span {
            color: var(--color-dark-gray);
            position: relative;
            text-decoration: none;
        }

        img {
            height: 200%;
            left: -1rem;
            -o-object-fit: cover;
            object-fit: cover;
            pointer-events: none;
            position: absolute;
            top: -50%;
            width: calc(100% + 1rem);
        }
    }
}

.Cart-eur {
    font-size: 80%;
    text-align: end;
}

.Cart-voucherFormResponse {
    font-family: var(--font-family-tt-commons-regular);
    letter-spacing: var(--letter-spacing-tt-commons);
    margin-top: calc(var(--gutter) * 0.75);
    text-transform: uppercase;

    &.is-success {
        color: var(--color-green);
    }

    &.is-notfound,
    &.is-error {
        color: var(--color-red);
    }
}

.Cart-footer {
    border-top: 1px solid var(--color-medium-gray);
    padding: 0 var(--gutter) var(--gutter);
}

.Cart-footerDetails {
    font-size: var(--font-size-canela-s);
    margin-bottom: var(--gutter);
    padding-top: var(--gutter);

    .Cart--checkout & {
        font-family: var(--font-family-tt-commons-medium);
        font-size: var(--font-size-tt-commons-m);
        letter-spacing: var(--letter-spacing-tt-commons);
        margin-bottom: 0;
        text-transform: uppercase;
    }
}

.Cart-footerDetailRow {
    display: flex;
    justify-content: space-between;

    dt {
        padding-right: var(--gutter);
    }

    dd {
    }
}

.Cart-footerDetailRow--discount {
    dd {
        color: var(--color-red);
    }
}

.Cart-checkoutLink {
    align-items: center;
    background-color: var(--color-black);
    color: var(--color-white);
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-l);
    height: calc(var(--row-height) * 1.5);
    justify-content: center;
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: 0 var(--gutter);
    text-transform: uppercase;

    &.is-disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
