/** @define Header; weak */

.Header {
    background-color: var(--color-white);
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 5;
}

.Header-top {
    background: var(--color-black);
    color: var(--color-white);
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: 0.04em;
    line-height: 1.4;

    .Header-navWrapper {
        display: flex;
        justify-content: space-between;
    }

    .Header-nav {
        &:after {
            display: none;
        }
    }
}

.Header-topMenuContent {
    color: var(--color-white);
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: 0.04em;
    line-height: 1.4;
    max-width: 20em;
    padding: var(--gutter);
}

.Header-topMenuButtons {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gutter) / 2);

    button {
        align-items: center;
        border: 1px solid var(--color-medium-gray);
        display: flex;
        gap: calc(var(--gutter) / 2);
        justify-content: center;
        padding: calc(var(--gutter) / 2) var(--gutter);

        &:hover {
            background-color: black;
        }
    }
}

.Header-content {
    align-items: center;
    border-bottom: 1px solid var(--color-medium-gray);
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    height: calc(var(--row-height) * 1.5);
    letter-spacing: var(--letter-spacing-tt-commons);
    text-transform: uppercase;
}

.Header-skipToContentLink {
    background: var(--color-black);
    bottom: 100%;
    color: var(--color-white);
    font-family: var(--font-family-tt-commons-regular);
    left: 50%;
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: var(--gutter);
    position: absolute;
    text-transform: uppercase;
    transform: translate(-50%, -150%);
    z-index: 102;

    &:focus-visible {
        transform: translate(-50%, 0);
    }
}

.Header-center {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 var(--gutter);

    @media (max-width: ($desktop-width - 1px)), (min-width: 1300px) {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
    }

    @media (min-width: $desktop-width) and (max-width: 1299px) {
        order: -1;
    }
}

.Header-wordmarkWrapper {
    display: flex;
    justify-content: center;
}

.Header-wordmark {
    height: 1.1rem;
    width: auto;

    @media (min-width: $tablet-width) {
        height: 1.2rem;
    }
}

.Header-nav,
.Header-menuToggle {
    &:after {
        background-color: var(--color-medium-gray);
        display: block;
        height: calc(var(--row-height) * 0.75);
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;

        @media (min-width: $tablet-width) {
            content: "";
        }
    }
}

.Header-menuToggle {
    align-items: center;
    display: flex;
    height: 100%;
    outline: 0;
    padding: 0 var(--gutter);
    position: relative;
    text-transform: inherit;

    @media (min-width: $desktop-width) {
        display: none;
    }

    &.is-showingMenu {
        background-color: var(--color-light-gray);

        + .Header-accountButton {
            display: none;
        }
    }

    i {
        font-size: 1.7rem;
        position: relative;
        top: -0.1rem;

        + i {
            margin-left: var(--gutter);
        }
    }

    span {
        margin-left: var(--gutter);
    }

    &.is-showingMenu:after {
        content: "";
        height: 100%;
    }
}

.Header-navWrapper {
    align-items: center;
    align-self: stretch;
    display: flex;
    position: relative;
    text-transform: uppercase;

    @media (min-width: 1200px) {
        flex-shrink: 0;
        min-width: 38%;
    }

    @media (min-width: 1440px) {
        min-width: 40%;
    }
}

.Header-navWrapper--right {
    margin-left: auto;
    padding: 0 var(--gutter);

    @media (min-width: $tablet-width) {
        justify-content: flex-end;
    }
}

.Header-nav {
    align-self: stretch;
    display: none;
    list-style: none;
    margin: 0;
    padding: 0 var(--gutter);
    position: relative;

    @media (min-width: $desktop-width) {
        display: flex;
    }
}

.Header-nav--left {
}

.Header-nav--right {
    margin-right: var(--gutter);
}

.Header-navItem {
    align-items: center;
    display: flex;
    height: 100%;
    padding: calc(var(--gutter) / 2) 0;
    white-space: nowrap;

    > button:hover span,
    > a:hover span,
    &.is-active span {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }

    &.is-red {
        color: var(--color-red);
    }

    & + & {
        margin-left: var(--gutter);
    }
}

.Header-searchToggle {
    align-self: stretch;
    padding: var(--gutter);

    @media (max-width: ($desktop-width - 1px)) {
        display: none;
    }

    &.is-active {
        background-color: var(--color-light-gray);
        box-shadow: -1px 0 0 var(--color-medium-gray),
            1px 0 0 var(--color-medium-gray);
    }

    i {
        display: block;
        font-size: 1.5rem;
    }

    @media (hover) {
        &:hover:not(.is-active) i {
            color: var(--color-dark-gray);
        }
    }
}

.Header-searchCloser {
    align-self: stretch;
    width: 1em;
}

.Header-buttons {
    display: flex;

    @media (min-width: $tablet-width) {
        // Align the border to the left of .Header-buttons with the border of
        // .Breadcrumbs-sortButton
        min-width: calc(7.25rem - var(--gutter) * 2 - 2px);
    }
}

.Header-accountButton,
.Header-cartButton {
    @media (hover) {
        &:hover {
            color: var(--color-dark-gray);
        }
    }

    i {
        font-size: 1.5rem;
    }
}

.Header-accountButton--desktop {
    @media (max-width: ($tablet-width - 1px)) {
        display: none;
    }
}

.Header-accountButton--mobile {
    @media (min-width: $tablet-width) {
        display: none;
    }
}

.Header-cartButton {
    display: flex;
    margin-left: var(--gutter);
    position: relative;
    top: 0.1em;

    span {
        left: 1.25em;
        line-height: 1;
        position: absolute;
        top: -0.3em;
    }
}

.Header-globeButton {
    margin-right: var(--gutter);
    position: relative;

    i {
        font-size: 1.5rem;
    }
}

.Header-accountButton {
    top: 0.1em;
}

.Header-currencyOptions {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-medium-gray);
    border-left: 1px solid var(--color-medium-gray);
    border-right: none;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    padding: 1em;
    position: absolute;
    right: 0;
    top: 100%;

    .is-active {
        text-decoration: underline;
    }
}

.Header-currencyDropdown {
    cursor: pointer;
    margin-left: var(--gutter);
    margin-top: 0.1em;
}

@keyframes Header-notification {
    to {
        transform: translateX(-100%);
    }
}

.Header-notification {
    align-items: center;
    background-color: var(--color-light-gray);
    border-bottom: 1px solid var(--color-medium-gray);
    display: flex;
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    height: var(--row-height);
    letter-spacing: var(--letter-spacing-tt-commons);
    overflow: hidden;
    text-transform: uppercase;
    white-space: nowrap;

    p {
        font-family: var(--font-family-tt-commons-medium);
        font-size: var(--font-size-tt-commons-m);
        letter-spacing: var(--letter-spacing-tt-commons);
        text-transform: uppercase;
    }

    > * {
        animation: Header-notification 18s linear infinite;
        display: inline-block;
        font: inherit;
        margin: 0 !important;
        padding: 0 5%;
    }

    &.--bg-black {
        background-color: var(--color-black);

        > * {
            color: var(--color-white);
        }
    }

    a {
        background-image: none;
    }
}

.Header-menuModal {
    inset: 0;
    outline: none;
    pointer-events: none;
    position: absolute;
}

.Header-menu {
    background-color: #fff;
    border-bottom: 1px solid var(--color-medium-gray);
    font-family: var(--font-family-tt-commons-medium);
    letter-spacing: var(--letter-spacing-tt-commons);
    overflow: auto;
    pointer-events: all;
    position: relative;
    text-transform: uppercase;

    @media (max-width: ($tablet-width - 1px)) {
        display: none;
    }
}

.Header-menu--search {
    background-image: none;
    display: block;
    padding-bottom: calc(var(--gutter) * 1.5);
    padding-top: calc(var(--gutter) * 1.5);
}

.Header-menuContent {
    background-image: linear-gradient(
        to top,
        var(--color-medium-gray),
        var(--color-medium-gray)
    ),
        linear-gradient(
        to top,
        var(--color-medium-gray),
        var(--color-medium-gray)
    ),
        linear-gradient(
        to top,
        var(--color-medium-gray),
        var(--color-medium-gray)
    );
    background-position: 20% center, 40% center, 60% center;
    background-repeat: no-repeat;
    background-size: 1px calc(100% - var(--gutter) * 1.5);
    display: grid;
    grid-template-areas: "header featured" "nav featured";
    grid-template-columns: 60% 1fr;
    grid-template-rows: min-content 1fr;
    padding: var(--gutter) 0;
}

.Header-menuContent--rightLinks {
    // grid-template-columns: repeat(5, 1fr);
}

.Header-menuHeader {
    align-items: center;
    display: flex;
    grid-area: header;
    justify-content: space-between;
    padding: var(--gutter);
}

.Header-menuClose {
    color: var(--color-dark-gray);

    @media (hover) {
        &:hover {
            background-image: linear-gradient(to top, #959595, #959595);
            background-position: left 100%;
            background-repeat: no-repeat;
            background-size: 100% 1px;
        }
    }

    i {
        margin-right: 0.5em;
    }
}

.Header-menuColumns {
    column-count: 3;
    grid-area: nav;
    list-style: none;
    margin: 0;
    padding: 0;
}

.Header-menuColItem {
    break-inside: avoid;
    display: inline-block;
    padding: var(--gutter);
    width: 100%;
}

.Header-menuColItem--brand {
    min-height: 10em;
    padding-top: 0;
}

.Header-menuList {
    line-height: 1.5;
    list-style: none;
    margin: 0;
    padding: 0;
}

.Header-menuList--padded {
    padding: 0 var(--gutter) var(--gutter);
}

.Header-menuListItem {
    padding: 0.25em 0 0.25em 1em;
    text-indent: -1em;

    @media (min-width: $desktop-width) {
        padding: 0 0 0 1em;
    }

    @media (hover) {
        a:hover {
            background-image: linear-gradient(to top, #959595, #959595);
            background-position: left 100%;
            background-repeat: no-repeat;
            background-size: 100% 1px;
        }
    }

    i {
        margin: 0 0.35em;
    }

    span,
    i {
        color: var(--color-dark-gray);
    }
}

.Header-menuListItem--heading {
    font-family: var(--font-family-tt-commons-demibold);
}

.Header-menuListItem--viewAll {
    color: var(--color-dark-gray);
}

.Header-menuLetterHeading {
    font-family: var(--font-family-canela-text);
    font-size: var(--font-size-canela-m);
    line-height: 1;
    padding: var(--gutter) 0;
    text-transform: uppercase;
    width: 100%;
}

.Header-menuRightLinkText {
    grid-area: featured;
    padding: 0 var(--gutter);
    text-transform: none;
}

.Header-menuFeatured {
    grid-area: featured;

    .Header-menuContent--rightLinks & {
        display: grid;
        gap: var(--gutter);
        grid-area: nav;
        grid-template-columns: repeat(3, 1fr);
    }
}

.Header-menuFeaturedLinks {
    display: flex;
    flex-wrap: wrap;
    padding: 0 calc(var(--gutter) / 2);
    position: sticky;
    top: 0;

    .Header-menuContent--rightLinks & {
        display: grid;
        gap: var(--gutter);
        grid-column: span 3;
        grid-template-columns: repeat(3, 1fr);
    }
}

.Header-menuFeaturedLink {
    display: block;
    padding: 0 calc(var(--gutter) / 2);
    width: 50%;

    .Header-menuContent--rightLinks & {
        display: flex;
        flex-flow: column;
        width: 100%;

        // span two columns if only two items
        &:nth-child(2):last-child {
            aspect-ratio: 3 / 2;
            grid-column: span 2;

            @media screen and (max-width: 1120px) {
                height: 100%;
            }
        }

        :first-child {
            flex: 1;

            img {
                object-fit: cover;
            }
        }
    }
}

.Header-menuFeaturedLinkImage {
    height: 100%;
    opacity: 0;
    transition: opacity 0.2s linear;

    &.is-loaded {
        opacity: 1;
    }
}

.Header-menuFeaturedLinkText {
    margin-top: 1em;
}

.Header-menuSearch {
    margin: 0 auto;
    width: 41.65%;
}

.Header-menuSearchLabel {
    display: block;
    position: relative;
    text-transform: none;
}

.Header-menuSearchInput {
    border: 1px solid var(--color-medium-gray);
    display: block;
    font-family: var(--font-family-canela-deck);
    font-size: var(--font-size-canela-s);
    height: calc(var(--font-size-canela-s) * 2.5);
    letter-spacing: revert;
    line-height: 1;
    outline: 0;
    padding: 0 0.5em;
    width: 100%;
}

.Header-menuSearchSpinner,
.Header-menuSearchButton {
    --size: calc(var(--font-size-canela-s) * 2.5);

    height: var(--size);
    position: absolute;
    right: 0;
    top: 0;
    width: var(--size);
}

.Header-menuSearchButton {
    i {
        color: var(--color-dark-gray);
        font-size: 1.5rem;
        vertical-align: -0.1em;
    }

    &:hover i {
        color: inherit;
    }

    span {
        @include visually-hidden;
    }
}

.Header-menuSearchHeading {
    font-family: var(--font-family-tt-commons-demibold);
    padding: var(--gutter);
}

.Header-menuSearchHeading--noPadding {
    padding: var(--gutter) 0;
}

.Header-menuSearchResults {
    --gutter: 1.15rem;

    border-top: 1px solid var(--color-medium-gray);
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    margin-bottom: -1px;
    overflow: hidden;
    padding: 0;
    text-transform: none;

    @media (min-width: 1100px) {
        grid-template-columns: repeat(6, 1fr);
    }
}

.Header-menuSearchFooter {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--gutter) * 2);
    padding: 0 var(--gutter) var(--gutter);
}

.Header-menuSearchPaginationButton {
    background-color: var(--color-black);
    border: 0;
    color: var(--color-white);
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    height: var(--row-height);
    letter-spacing: var(--letter-spacing-tt-commons);
    min-width: 8em;
    padding: 0 var(--gutter);
    text-transform: uppercase;

    &:disabled {
        opacity: 0.65;
    }
}
