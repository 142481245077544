@font-face {
    font-display: block;
    font-family: "artilleriet-icons";
    font-style: normal;
    font-weight: normal;
    src: url("../public/fonts/artilleriet-icons.ttf") format("truetype"),
        url("../public/fonts/artilleriet-icons.woff") format("woff"),
        url("../public/fonts/artilleriet-icons.svg#artilleriet-icons")
        format("svg");
}

@font-face {
    font-display: block;
    font-family: "TT Commons Medium";
    font-style: normal;
    font-weight: normal;
    src: url("../public/fonts/tt_commons_medium-webfont.woff2") format("woff2");
}

@font-face {
    font-display: block;
    font-family: "TT Commons Regular";
    font-style: normal;
    font-weight: normal;
    src: url("../public/fonts/tt_commons_regular-webfont.woff2") format("woff2");
}

@font-face {
    font-display: block;
    font-family: "TT Commons Demibold";
    font-style: normal;
    font-weight: normal;
    src: url("../public/fonts/tt_commons_demibold-webfont.woff2")
        format("woff2");
}

@font-face {
    font-display: block;
    font-family: "Canela Text Regular";
    font-style: normal;
    font-weight: normal;
    src: url("../public/fonts/CanelaText-Regular-Web.woff2") format("woff2");
}

@font-face {
    font-display: block;
    font-family: "Canela Deck Regular";
    font-style: normal;
    font-weight: normal;
    src: url("../public/fonts/CanelaDeck-Regular-Web.woff2") format("woff2");
}
