@mixin visually-hidden {
    clip: rect(0 0 0 0);
    height: 1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}

$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;

:root {
    --color-black: #111111;
    --color-black-opacity: hsla(0, 0%, 0%, 0.175);
    --color-white: #ffffff;
    --color-light-gray: #f7f7f7;
    --color-medium-gray: #e4e4e4;
    --color-medium-gray-hover: hsl(0, 0%, 80%);
    --color-dark-gray: #7d7d7d;
    --color-red: #b6392a;
    --color-green: #496e1e;
    --color-text: #ede5e1;
    --row-height: calc(var(--line-height-regular) * 2.25rem);
    --gutter: 1rem;
    --vw: calc((100vw - var(--scrollbar-width, 0px)) / 100);
    --font-size-tt-commons-base: 12px;
    --font-size-tt-commons-m: 1.15rem;
    --font-size-tt-commons-l: 1.45rem;
    --font-size-tt-commons-xl: 1.85rem;
    --letter-spacing-tt-commons: 0.09em;
    // --font-size-canela-base: 1.075rem;
    --font-size-canela-base: 1rem;
    --font-size-canela-s: 1.2rem;
    --font-size-canela-m: 1.5rem;
    --font-size-canela-l: 1.75rem;
    --font-size-canela-xl: 2.85rem;
    --font-size-canela-xxl: 3.15rem;
    --font-size-canela-xxxl: 4rem;
    --line-height-regular: 1.25;
    --font-family-canela-deck: "Canela Deck Regular", serif;
    --font-family-canela-text: "Canela Text Regular", serif;
    --font-family-tt-commons-demibold: "TT Commons Demibold", sans-serif;
    --font-family-tt-commons-medium: "TT Commons Medium", sans-serif;
    --font-family-tt-commons-regular: "TT Commons Regular", sans-serif;
}

@media (min-width: $tablet-width) {
    :root {
        --gutter: 1.25rem;
        // --font-size-tt-commons-base: 13px;
        --font-size-tt-commons-m: 1.2rem;
        --font-size-tt-commons-l: 1.55rem;
        --font-size-tt-commons-xl: 2rem;
        --font-size-canela-m: 1.75rem;
        --font-size-canela-l: 1.9rem;
        --font-size-canela-xl: 2.65rem;
        --font-size-canela-xxl: 3.75rem;
        --font-size-canela-xxxl: 6rem;
    }
}

@media (min-width: 1200px) {
    :root {
        --font-size-canela-xl: 3.25rem;
        --font-size-canela-xxl: 4.65rem;
        --font-size-canela-xxxl: 7rem;
    }
}

@media (min-width: 1800px) {
    :root {
        --font-size-tt-commons-base: 14px;
        --font-size-tt-commons-m: 1.15rem;
        --font-size-tt-commons-l: 1.35rem;
        --font-size-tt-commons-xl: 1.65rem;
        --font-size-canela-s: 1.15rem;
        --font-size-canela-xxxl: 9.5rem;
    }
}
