.Panel-wrapper {
    display: flex;
    inset: 0;
    outline: none;
    pointer-events: none;
    position: absolute;
}
.Panel {
    background-color: var(--color-white);
    box-shadow: 1px 0 0 var(--color-medium-gray) inset;
    margin-left: auto;
    pointer-events: all;
    width: 85%;

    @media (min-width: $tablet-width) {
        width: 40%;
    }

    @media (min-width: 1100px) {
        width: 25%;
    }
}
.Panel-header {
    align-items: center;
    background-color: var(--color-white);
    border-left: 1px solid var(--color-medium-gray);
    display: flex;
    height: calc(var(--header-height) + 1px);
    justify-content: space-between;
}
.Panel-heading {
    font-family: var(--font-family-tt-commons-demibold);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: 0 var(--gutter);
    text-transform: uppercase;
}
.Panel-closeButton {
    align-items: center;
    border-left: 1px solid var(--color-medium-gray);
    display: flex;
    height: calc(var(--header-height) - 1px);
    justify-content: center;
    padding: 0;
    width: calc(var(--header-height) - 1px);

    @media (hover) {
        &:hover {
            background-color: var(--color-light-gray);
        }
    }

    i {
        font-size: 1.5rem;
    }
}

.Panel-tabsWrapper {
    --summary-height: var(--row-height);

    padding-top: var(--summary-height);
}
.Panel-tabs {
    position: relative;
}
.Panel-tab {
}
.Panel-tabSummary {
    background-color: var(--color-white);
    bottom: 100%;
    cursor: pointer;
    display: block;
    font-family: var(--font-family-tt-commons-medium);
    left: calc(100% / var(--tab-count) * var(--tab-index));
    letter-spacing: var(--letter-spacing-tt-commons);
    list-style-type: none;
    position: absolute;
    text-transform: uppercase;
    white-space: nowrap;
    width: calc(100% / var(--tab-count));

    .Panel-tab[open] & {
        background-color: var(--color-light-gray);
    }

    &::-webkit-details-marker {
        display: none;
    }

    > div {
        align-items: center;
        border: 1px solid var(--color-medium-gray);
        border-right: 0;
        display: flex;
        height: var(--summary-height);
        justify-content: center;
        padding: 0 calc(var(--gutter) / 2);
    }
}
.Panel-tabContent {
    background-color: var(--color-white);
    border-left: 1px solid var(--color-medium-gray);
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: 0.04em;
    line-height: 1.4;

    > div {
        padding: var(--gutter);
    }

    strong {
        font-weight: normal;
    }
    a {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }
    p:last-child {
        margin-bottom: 0;
    }
    dl > div {
        display: flex;
    }
    dl > div {
        margin-bottom: var(--gutter);
    }
    dt {
        margin-right: 1em;
        min-width: 20%;

        @media (min-width: $tablet-width) {
            min-width: 18%;
        }
    }
}
