/** @define CookieNotice; weak */

.CookieNotice {
    background-color: var(--color-white);
    border: 1px solid var(--color-medium-gray);
    bottom: var(--gutter);
    left: var(--gutter);
    max-width: calc(100% - var(--gutter) * 2);
    position: fixed;
    // We want .CookieNotice to appear above the Freshdesk chat. That's why we
    // use this extremely high z-index
    z-index: 2147483601;

    &.is-closed {
        display: none;
    }
}
.CookieNotice-content {
    @media (min-width: $mobile-width) {
        display: flex;
    }
}
.CookieNotice-text {
    flex: 1;
    font-family: var(--font-family-tt-commons-regular);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: 0.04em;
    line-height: 1.4;
    padding: var(--gutter);

    a {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
    }
}
.CookieNotice-closeButton {
    font-family: var(--font-family-tt-commons-medium);
    font-size: var(--font-size-tt-commons-m);
    letter-spacing: var(--letter-spacing-tt-commons);
    padding: var(--gutter);
    text-transform: uppercase;

    @media (min-width: $mobile-width) {
        border-left: 1px solid var(--color-medium-gray);
    }
}
