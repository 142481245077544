.u-richText,
.u-richTextNoUnderlines {
    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: var(--font-family-canela-deck);
        font-weight: normal;
        letter-spacing: -0.0175em;
        line-height: 0.9;
        margin: 1.75rem 0 1rem;

        & + h1,
        & + h2,
        & + h3,
        & + h4 {
            margin-top: 0;
        }

        strong {
            font-weight: inherit;
        }
    }

    h1 {
        font-size: var(--font-size-canela-xxxl);
    }

    h2 {
        font-size: var(--font-size-canela-xxl);
    }

    h3 {
        font-size: var(--font-size-canela-xl);
    }

    h4 {
        font-size: var(--font-size-canela-l);
    }

    h5,
    h6 {
        font-family: var(--font-family-tt-commons-demibold);
        font-weight: normal;
        letter-spacing: var(--letter-spacing-tt-commons);
        line-height: 1;
        text-transform: uppercase;

        strong {
            font-weight: inherit;
        }
    }

    h5 {
        margin: 1.75em 0 0.7em;
    }

    h6 {
        margin: 1.25em 0 0.25em;
    }

    p,
    ul,
    ol {
        font-family: var(--font-family-tt-commons-regular);
        font-size: var(--font-size-tt-commons-m);
        letter-spacing: 0.04em;
        line-height: 1.4;
        margin-bottom: 1em;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    ol {
        list-style-position: inside;
        padding: 0;
    }
}

.u-richText {
    a,
    u {
        background-image: linear-gradient(to top, #959595, #959595);
        background-position: left 100%;
        background-repeat: no-repeat;
        background-size: 100% 1px;
        text-decoration: none;
    }
}

.u-richTextNoUnderlines {
    @media (hover) {
        a:hover {
            background-image: linear-gradient(
                to top,
                hsl(0, 0%, 18%),
                hsl(0, 0%, 18%)
            );
            background-position: left 100%;
            background-repeat: no-repeat;
            background-size: 100% 1px;
        }
    }
}

.u-visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
